/* Customize your main colors in :root variables */
:root {
  --main-background-color: #093aeb;
  --card-background-color: #804980;
  --countdown-background-color: #433765;
  --main-text-color:#F7F6F4;
  --title-text-color:#3CBA8B;
}
img { border-style: none; }
#background-video {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

h2, p{
  color: var(--main-text-color);
}

h3{
font-weight: normal;
}



